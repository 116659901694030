<template>
    <div v-if="showEdit" class="postJobWrapper">
        <div class="exit_zone" @click="toggleEditWindow"></div>
        <div>
            <div class="form_section">
                <div class="w-full pl-1 pr-1 flex justify-between">
                    <span id="job">{{ $t("Edit project") }}</span>
                    <button class="nav-btn trash-btn" @click="toggleEditWindow">
                        <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                        <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                    </button>
                </div>
                <form action="POST" @submit.prevent="onSubmit" ref="form1">
                    <!-- ... your form content for section 1 ... -->
                    <div class="input_group">
                        <input type="text" id="project_name" name="project_name" v-model="newProject.name" @focus="isInputFocused = true" required /><label
                            class="required floating_label"
                            :class="{ active: newProject.name }"
                            for="project_name"
                        >
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/job_icon.svg" alt="" />
                            {{ $t("Project name") }}</label
                        >
                        <span v-if="requiredFields.project_name" class="err_msg">{{ requiredFields.project_name }} </span>
                    </div>

                    <div class="input_group">
                        <input type="text" id="job_title" name="job_title" v-model="newProject.jobTitle" @focus="isInputFocused = true" required /><label
                            class="required floating_label"
                            :class="{ active: newProject.jobTitle }"
                            for="project_name"
                        >
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/Archeive.svg" alt="" />
                            {{ $t("Job Title") }}</label
                        >
                        <span v-if="requiredFields.job_title" class="err_msg">{{ requiredFields.job_title }} </span>
                    </div>
                    <div class="input_group select">
                        <label class="required select_label" for="job_seniority"><img loading="lazy" decoding="async" src="../assets/Images/icons/seniority_icon.svg" alt="" /></label>
                        <VueMultiselect
                            :placeholder="$t('select job seniority')"
                            class="skills"
                            :value="this.project.seniority"
                            v-model="newProject.seniority"
                            :options="seniorities"
                            :taggable="true"
                            id="job_seniority"
                            :allowEmpty="false"
                        />
                        <span v-if="requiredFields.job_seniority" class="err_msg">{{ requiredFields.job_seniority }}</span>
                    </div>
                </form>
            </div>
            <div class="btn_wrapper">
                <input class="previous" type="submit" :value="$t('Edit assessments')" @click="editAssessments" />
                <input class="next" type="submit" :value="$t('save')" @click="EditProject" />
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { useStore } from "../store/index";
import axios from "axios";

export default {
    name: "EditProject",
    components: { VueMultiselect },
    props: {
        showEdit: Boolean,
        toggleEditWindow: Function,
        project: Object,
        editAssessments: Function,
        showNotif: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            job: {
                title: "",
                seniority: "",
                compensation: "",
                department: "",
                skills: [],
                salary: null,
                description: "",
                hide_salary: false,
                company_name: this.Store.company_name,
            },
            newProject: this.project,
            requiredFields: {
                project_name: "",
                job_title: "",
                job_seniority: "",
            },

            seniorities: ["Internship", "Entry Level", "Junior", "Senior", "VP", "Executive"],
        };
    },
    methods: {
        validateForm() {
            // Reset requiredFields object
            this.requiredFields = {};

            // Check for required fields
            if (!this.project.name) this.requiredFields.project_name = "Project name is required";
            if (!this.project.jobTitle) this.requiredFields.job_title = "Job title is required";
            if (!this.project.seniority) this.requiredFields.job_seniority = "Job seniority is required";

            // Check if all required fields are filled
            return Object.keys(this.requiredFields).length === 0;
        },

        async EditProject() {
            console.log("PostJob");
            console.log(this.validateForm());
            if (this.validateForm()) {
                console.log({ newProject: this.newProject });
                let data = JSON.stringify({
                    projectData: this.newProject,
                    id: this.project._id,
                });

                let config = {
                    method: "put",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/projects/update`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };

                axios
                    .request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        this.toggleEditWindow();
                        this.showNotif("project updated successfully", true);
                    })
                    .catch((error) => {
                        console.log(error);
                        alert("there was an arror");
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.postJobWrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    > :nth-child(2) {
        background: #fff;
        width: 40%;
        padding: 1rem 2rem;
        border-radius: 20px;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        position: relative;

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 50%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            height: 60%;
        }

        .log_in {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: absolute;
            bottom: 15%;
            top: 25%;

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
                color: #5a5a5d;

                > :first-child {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 14px;
                }

                /*>:nth-child(5){
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem 0 0;
                }*/
                .captcha {
                    padding: 1rem;

                    > :first-child {
                        > :first-child {
                            width: 100% !important;
                        }
                    }
                }
            }

            .sign_up {
                font-weight: 400;
                font-size: 19.96px;
                line-height: 100%;
                color: #bebebf;
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
            }

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                gap: 4rem;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
    }
}

.exit_zone {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.form_section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    > :first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2196f3;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: #e5e5ef;
            margin: 1rem 0;
        }
    }
}

.err_msg {
    color: #ff6969;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 125%;
    left: 0;
    z-index: 30;
    padding: 0 1% 0 0;
    margin-left: 25px;
    transform: translateY(-50%);
    pointer-events: none;
    background: #fff;
}

.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
    margin: 0.5rem 0;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #05152e;

        img {
            margin-right: 5px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 25px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input,
    textarea {
        border: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 20px;
        width: 100%;
        padding: 8px 1.5rem;
    }

    input {
        height: 3rem;
    }

    textarea {
        resize: none;
    }
}

.btn_wrapper {
    height: 100px;
    width: 70%;
    margin: 0 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    bottom: 0%;

    .previous {
        height: 50px;
        width: 45%;
        background-color: #fff;
        color: #2196f3;
        border: 1px solid #2196f3;
        border-radius: 20px;
        font-weight: 500;
        font-size: 20px;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: #2196f3;
            color: #fff;
            border: 1px solid #fff;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }

    .next {
        height: 50px;
        width: 40%;
        background-color: #2196f3;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
        font-weight: 500;
        font-size: 20px;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #fff;
            color: #2196f3;
            border: 1px solid #2196f3;
            cursor: pointer;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }
}

.select {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    border: 1px solid #ccd7ec;
    border-radius: 20px;
    width: 94%;
    margin: 0 3%;

    > :nth-child(2) {
        width: 99%;
        margin-left: 8%;
        float: right;
        right: 0;
        border: none;

        > :nth-child(2) {
            border: none;
        }
    }

    .select_label {
        position: absolute;
        top: 50%;
        left: 0%;
        z-index: 30;
        margin-left: 5px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
    }

    .err_msg {
        position: absolute;
        top: 150%;
        left: 0;
        z-index: 30;
        padding: 0 1% 0 0;
        margin: 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
    }
}

.trash-btn {
    .nav-icon {
        color: red;
    }
}

.trash-btn:hover {
    border: 2px solid red;
}
</style>
