<template>
    <div>
        <div class="emptyTest" v-if="assessment === null">
            {{ getIndexNbr(index) }}
        </div>
        <div class="emptyTest test relative" v-else draggable="true" @dragstart="handleDragStart(index, $event)" @dragover.prevent @drop="handleDrop(index, $event)">
            <span class="text-[12px]">
                {{ assessment.name }}
            </span>
            <button class="trash-btn delete-btn" @click="removeAssessment(assessment)">
                <font-awesome-icon :icon="['far', 'circle-xmark']" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmptyTest",
    props: {
        index: {
            type: Number,
            required: true,
        },
        assessment: {
            type: Object,
            required: true,
        },
        removeAssessment: {
            type: Function,
            required: true,
        },
        handleDrop: {
            type: Function,
        },
        handleDragOver: {
            type: Function,
        },
    },
    data() {
        return {
            showBtn: false,
        };
    },
    methods: {
        handleDragStart(index, event) {
            // Set the drag data to the index of the dragged item
            event.dataTransfer.setData("text/plain", index);
        },

        getIndexNbr(index) {
            index += 1;
            let testNbr = "";
            if (index === 1) {
                testNbr = "1st test";
            } else if (index === 2) {
                testNbr = "2nd test";
            } else if (index === 3) {
                testNbr = "3rd test";
            } else {
                testNbr = `${index}th test`;
            }
            return testNbr;
        },
    },
};
</script>

<style scoped lang="scss">
.emptyTest {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    border: 2px dashed #0f172a5b;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #0f172a5b;
    background-color: transparent;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    padding: 10px;
    cursor: grab;
    transition: all 0.3s ease;
    &:hover {
        background-color: #0f172a11;
    }
}
.test {
    background-color: #2371b6;
    color: #fff;
    padding: 2px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    .trash-btn {
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        background-color: #0f172a;

        .trash-btn {
            opacity: 1;
        }
        .delete-btn {
            opacity: 1;
        }
    }
    .delete-btn {
        position: absolute;
        top: -18px;
        right: -15px;
        color: #c70039;
        opacity: 0;
        transition: all ease 0.3s;
        font-size: 24px;
        font-weight: 700;

        > :first-child {
            background-color: #fff;
            border-radius: 50%;
        }
    }
}
</style>
