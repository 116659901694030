<template>
    <div v-if="compareModal" class="fixed top-0 left-0 right-0 bottom-0 z-[100] flex justify-center items-center bg-[rgba(0,0,0,35%)] w-full h-screen">
        <div class="fixed h-screen top-0 bottom-0 right-0 left-0 -z-10" @click="toggleCompare"></div>
        <div class="relative w-1/3 rounded-3xl bg-white p-4 flex flex-col justify-around items-center compareModal">
            <div class="w-full flex justify-end p-0 pr-5 mb-5">
                <button class="nav-btn trash-btn" @click="toggleCompare">
                    <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                    <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                </button>
            </div>
            <div v-if="candidates.length === 0" class="loader">
                <LoadingComponent />
            </div>
            <div v-else class="w-11/12 flex flex-col gap-3 overflow-y-scroll mb-8 candidatesList">
                <!-- <span class="w-fit px-3 py-2 rounded-3xl" {{ c.First_name }}</span> -->
                <div
                    v-for="c in candidates"
                    @click="Store.addCandidate(c)"
                    :key="c._id"
                    :class="Store.selectedCandidates.includes(c) ? 'bg-NeonBlue text-white' : 'bg-white text-gray-700'"
                    class="cursor-pointer border border-#d4d4d4 w-11/12 shadow-[0_0_2px_1px_rgba(0,0,0,5%)] flex flex-row justify-start gap-4 mx-auto items-center py-2 px-4 grid-rows-2 rounded-lg candidateCard"
                >
                    <!-- grid grid-cols-3 -->
                    <img loading="lazy" decoding="async" :src="getImgUrl(c.avatar)" :alt="c.First_name + ' ' + c.Last_name" class="col-start-1 col-end-2 row-span-2 w-14 h-14 rounded-full" />
                    <h1 class="col-start-2 row-start-1 font-bold text-left text-xl">{{ c.First_name + " " + c.Last_name }}</h1>
                    <h2 class="col-start-2 row-start-2 font-bold text-left text-2xl ml-auto text-[#2196f3]" :class="Store.selectedCandidates.includes(c) ? ' text-white' : ''">{{ c.avgScore }}%</h2>
                </div>
            </div>
            <div class="w-full flex flex-row justify-end items-center">
                <button @click="compareTalents" class="w-40 h-fit px-4 py-2 bg-NeonBlue text-white rounded-full text-xl font-bold text-center">
                    {{ $t("Next") }}
                </button>
            </div>
            <!-- <div class="w-2/5 flex flex-col gap-3">
                <button @click="Store.addCandidate(candidate)" v-for="candidate in candidates" :key="candidate._id" class="border border-red-500 p-4">
                    
                    <h1 class="text-2xl font-bold text-red-500">{{ candidate.First_name }}</h1>
                </button>
            </div> -->
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store";
import LoadingComponent from "../components/LoadingComponent.vue";

export default {
    name: "CompareModal",
    props: ["compareModal", "toggleCompare", "candidates", "projectId"],
    components: {
        LoadingComponent,
    },
    data() {
        return {
            // selectedCandidates:[]
            isLoading: true,
        };
    },
    watch: {
        candidates(newVal) {
            console.log({ newVal });
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    // watch:{
    //     projectId(oldVal, newVal){
    //         this.Store.transformEvaluations(newVal)
    //     }
    // },
    methods: {
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                // console.log({ image });
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
        compareTalents() {
            this.Store.selectedCandidates.length > 1 ? this.$router.push(`/CompareTalent/?projectId=${this.projectId}`) : alert("You need to select at least 2 candidates");
        },
    },
    mounted() {
        // this.Store.transformEvaluations(this.projectId)
        // console.log('the evaluation......', this.Store.evaluations)
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 4px; /* Set the width of the scrollbar */
    background-color: #2196f334;
    border-radius: 500px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
    border-radius: 40px; /* Set the border-radius to 40px for rounded corners */
    background-color: #2196f3; /* Set the background color of the scrollbar thumb */
}

.compareModal {
    height: fit-content;
    max-height: 80%;
}
.trash-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .nav-icon {
        color: red;
    }
}

.trash-btn:hover {
    border: 2px solid red;
}
.candidateCard {
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
}
.candidatesList {
    > :first-child,
    :last-child {
        &:hover {
            transform: scaleX(1.05);
        }
    }
}
.loader {
    width: 100%;
    // height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
