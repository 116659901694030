<template>
    <div v-if="show" class="CompanyProfileContainer">
        <div @click="toggleModal" class="exit_zone"></div>
        <div class="settings">
            <div>
                <span>Personal Profile</span>
                <button class="save_btn" :disabled="disabled" :class="disabled ? 'disabled' : 'enabled'" @click="updateProfile">Save changes</button>
            </div>
            <div>
                <personal_infos>
                    <profileStep title="Company Profile">
                        <form action="POST">
                            <div class="input_group">
                                <!-- <label for="company_name">Company Name {{ company.name }} </label> -->
                                <!-- <input type="text" id="company_name" v-model="company.name" @keyup="enableBtn" /> -->
                            </div>
                            <div class="input_group">
                                <label for="tagline">Tagline</label>
                                <input type="text" id="tagline" v-model="company.slogan" @keyup="enableBtn" />
                            </div>

                            <div class="input_group">
                                <label for="location">Location</label>
                                <input type="text" id="location" v-model="company.location" @keyup="enableBtn" />
                            </div>
                            <div class="input_group">
                                <label for="categpry">Category</label>
                                <input type="text" id="category" v-model="company.industry" @keyup="enableBtn" />
                            </div>
                            <div class="input_group2">
                                <div class="input_group">
                                    <label for="staf_count">Employees Count</label>
                                    <select name="" id="staf_count" v-model="company.employees_count" @change="enableBtn">
                                        <option value=""></option>
                                        <option value="1-50">1-50</option>
                                        <option value="51-100">50-100</option>
                                        <option value="101-150">101-150</option>
                                    </select>
                                </div>

                                <div class="input_group">
                                    <label for="type">Work Type</label>
                                    <select name="" id="type" v-model="company.type" @change="enableBtn">
                                        <option value=""></option>
                                        <option value="On site">On site</option>
                                        <option value="Remote">Remote</option>
                                        <option value="Hybrid">Hybrid</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </profileStep>
                    <profileStep title="Overview">
                        <form action="POST">
                            <QuillEditor theme="snow" toolbar="full" v-model:content="company.description" contentType="html" @keyup="enableBtn" />
                            <VueMultiselect v-model="company.taglist" trackBy="title" label="title" :options="taglist" @input="enableBtn" :multiple="true" :taggable="true" id="team">
                                <template v-slot:default="taglist">
                                    <span class="option__desc">
                                        <span class="option__title">{{ taglist.option.title }}</span>
                                    </span>
                                </template>
                                <template v-slot:option="taglist">
                                    <img loading="lazy" decoding="async" class="option__image" :src="require(`@/assets/Images/icons/${taglist.option.image}`)" width="30" height="30" />
                                    <div class="option__desc">
                                        <span class="option__title">{{ taglist.option.title }}</span>
                                        <span class="option__small">{{ taglist.option.text }}</span>
                                    </div>
                                </template>
                            </VueMultiselect>
                        </form>
                    </profileStep>
                    <profileStep title="Contacts">
                        <form action="POST">
                            <div class="input_group">
                                <label for="type">Benefits</label>
                                <VueMultiselect
                                    v-model="company.benefit_list"
                                    trackBy="benefit"
                                    label="benefit"
                                    :options="benefit_list"
                                    :multiple="true"
                                    :taggable="true"
                                    :searchable="false"
                                    id="type"
                                    @input="enableBtn"
                                />
                            </div>
                            <div class="input_group">
                                <label for="team">Your Team</label>
                                <VueMultiselect v-model="company.team" trackBy="name" label="name" :options="team" @input="enableBtn" :multiple="true" :taggable="true" id="team">
                                    <template v-slot:default="team">
                                        <span class="option__desc">
                                            <span class="option__title">{{ team.option.name }}</span>
                                        </span>
                                    </template>
                                    <template v-slot:option="team">
                                        <img loading="lazy" decoding="async" class="option__image" :src="require(`@/assets/Images/icons/${team.option.image}`)" width="30" height="30" />
                                        <div class="option__desc">
                                            <span class="option__title">{{ team.option.name }}</span>
                                            <span class="option__small">{{ team.option.role }}</span>
                                        </div>
                                    </template>
                                </VueMultiselect>
                            </div>

                            <div class="input_group">
                                <label for="website">Website</label>
                                <input type="text" id="website" v-model="company.website" @keyup="enableBtn" />
                            </div>
                            <div class="input_group">
                                <label for="facebook">Facbook</label>
                                <input type="text" id="facebook" v-model="company.facebook" @keyup="enableBtn" />
                            </div>
                            <div class="input_group">
                                <label for="linkedIn">LinkedIn</label>
                                <input type="text" id="linkedIn" v-model="company.linkedIn" @keyup="enableBtn" />
                            </div>
                        </form>
                    </profileStep>
                    <profileStep title="Security">
                        <form action="POST">
                            <div class="input_group pad_right_0">
                                <label for="current_password">Current Password</label>
                                <div>
                                    <input :type="show_currentPass ? 'text' : 'password'" name="current_password" id="current_password" />
                                    <button @click.prevent="show_currentPass = !show_currentPass">
                                        <img
                                            loading="lazy"
                                            decoding="async"
                                            :src="require(`../assets/Images/icons/${show_currentPass ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)"
                                            width="25"
                                            height="25"
                                            alt=""
                                        />
                                    </button>
                                </div>
                            </div>
                            <div class="input_group pad_right_0">
                                <label for="new_password">New Password</label>
                                <div>
                                    <input :type="showPassword ? 'text' : 'password'" name="new_password" id="new_password" />
                                    <button @click.prevent="showPassword = !showPassword">
                                        <img
                                            loading="lazy"
                                            decoding="async"
                                            :src="require(`../assets/Images/icons/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)"
                                            width="25"
                                            height="25"
                                            alt=""
                                        />
                                    </button>
                                </div>
                            </div>
                            <div class="input_group pad_right_0">
                                <label for="confirm_password">Confirm Password</label>
                                <div>
                                    <input :type="showPassword ? 'text' : 'password'" name="confirm_password" id="confirm_password" />
                                    <button @click.prevent="showPassword = !showPassword">
                                        <img
                                            loading="lazy"
                                            decoding="async"
                                            :src="require(`../assets/Images/icons/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)"
                                            width="25"
                                            height="25"
                                            alt=""
                                        />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </profileStep>
                </personal_infos>
            </div>
            <div>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/lock_icon.svg" alt="Privacy" width="40" height="40" />
                <span>We keep your data private and never share it with third-parties.</span>
            </div>
        </div>
    </div>
</template>

<script>
import personal_infos from "@/components/personal_infos.vue";
import profileStep from "@/components/profile_step.vue";
import VueMultiselect from "vue-multiselect";
import "../../node_modules/vue-multiselect/dist/vue-multiselect.css";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
    name: "ProfileSettings",
    components: {
        personal_infos,
        profileStep,
        VueMultiselect,
        QuillEditor,
    },
    props: {
        show: { type: Boolean, required: true, default: false },
        toggleModal: { type: Function, required: true },
        companyProfile: { type: Object, required: true },
    },
    data() {
        return {
            show_currentPass: false,
            showPassword: false,
            disabled: true,

            options: ["Select option", "options", "selected", "multiple", "label", "searchable", "clearOnSelect", "hideSelected", "maxHeight", "allowEmpty", "showLabels", "onChange", "touched"],
            /*company:{
                    name: "Deall",
                    profile_image: "profile_image.svg",
                    cover_image: "company_cover.svg",
                    slogan: "We connect top talents with top companies",
                    category: "Tech",
                    location: "Jakerta",
                    staf_count: "1-50",
                    type: "On site",
                    website:{ type: String, default: "#"},
                    facebook:{ type: String, default: "#"},
                    linkedin:{ type: String, default: "#"},
                    about: "",
                    benefit_list:[],
                    team:[],
                    company_images:["finance.jpg","Norton_Icon.svg","profilepic.png","2378207.jpg","7489120.jpg"],
                    taglist: [
                        {image:"icon_idea.svg", title:"Iinnovative", text:"We strive to constantly pursue innovation of our products and services"},
                        {image:"icon_trust.svg", title:"Nurturing", text:"We strive to constantly pursue innovation of our products and services"},
                        {image:"icon_conflict.svg", title:"Energitic", text:"We strive to constantly pursue innovation of our products and services"},
                    ],
                },*/
            taglist: [
                {
                    image: "icon_idea.svg",
                    title: "Iinnovative",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_trust.svg",
                    title: "Nurturing",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_conflict.svg",
                    title: "Energitic",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_idea.svg",
                    title: "Sustainable",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_trust.svg",
                    title: "Clean",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_conflict.svg",
                    title: "Creatif",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
            ],
            benefit_list: [
                {
                    icon_path: "icon_hamburger.svg",
                    benefit: "Free Food and Snacks",
                },
                {
                    icon_path: "icon_globe.svg",
                    benefit: "Internation Exposuere",
                },
                {
                    icon_path: "icon_hospital_insurance.svg",
                    benefit: "Paid Sick Days",
                },
                {
                    icon_path: "icon_hospital_insurance.svg",
                    benefit: "THR/ Bonus System",
                },
                { icon_path: "icon_clothes.svg", benefit: "Casual Dress Code" },
                { icon_path: "icon_lunch.svg", benefit: "Free lunch" },
                {
                    icon_path: "icon_moneybag.svg",
                    benefit: "Competitive Salary",
                },
                { icon_path: "icon_more.svg", benefit: "More" },
            ],
            team: [
                {
                    image: "candidate_image.png",
                    name: "Abdellah Aouf",
                    role: "Chief Executive Officer",
                },
                {
                    image: "candidate_image.png",
                    name: "Abdelghani Bahri",
                    role: "Front End developper",
                },
                {
                    image: "candidate_image.png",
                    name: "Lamis Messaadi",
                    role: "Back End developper",
                },
                {
                    image: "candidate_image.png",
                    name: "Amira Gouasmi",
                    role: "Full Stack",
                },
                {
                    image: "candidate_image.png",
                    name: "Abhika Suderman",
                    role: "Chief Executive Officer",
                },
            ],
        };
    },
    computed: {
        company: function () {
            return this.companyProfile;
        },
    },
    methods: {
        enableBtn: function () {
            this.disabled = false;
        },
        updateProfile() {
            this.$emit("profileUpdated", this.company);
            console.log({ about: this.company.about });
        },
        closeModal: function () {
            this.toggleModal;
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.CompanyProfileContainer {
    font-family: "inter";
    font-style: normal;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        background: #fff;
        padding: 2%;
        width: 50%;
        height: 70%;
        border-radius: 10px;

        > :first-child {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        > :nth-child(2) {
            width: 100%;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            *:focus-visible {
                outline: none;
            }

            .input_group {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                div {
                    display: flex;
                    align-items: center;
                    border: 1px solid #e5e5ef;
                    height: 2.5rem;
                    border-radius: 4px;

                    button {
                        width: auto;
                        height: fit-content;
                        background: none;
                        border: none;
                    }
                    input {
                        border: none;
                        &:focus-visible {
                            border: none;
                        }
                    }
                }
            }
            .input_group2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;

                div {
                    width: 100%;
                }
            }
            .input_group3 {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
        }
        > :last-child {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background: #ecf5f9;
            margin-top: 1.5rem;
        }
        input,
        select {
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 2rem;
            width: 100%;
        }
        .enabled {
            cursor: pointer;
            background: #2196f3;
        }
        .disabled {
            cursor: not-allowed;
            background: rgba(74, 187, 251, 1);
        }
    }
}
.exit_zone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.input_image {
    border: 1px dashed #d5d5d5;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 5rem;
    align-items: center;

    input {
        display: none;
    }
    label {
        padding: 5%;
        background: rgba(231, 231, 231, 0.5);
        border-radius: 10px;
    }
}
.save_btn {
    padding: 2% 3%;
    border-radius: 50px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.browse_btn {
    background: #fff;
    padding: 2%;
    border-radius: 50px;
    border: 1px solid #bdbdbd;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: #dbdbdc;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #b8b8b9;
}
textarea {
    resize: none;
}

.option__desc {
    display: flex;
    flex-direction: column;
    color: #2f343a;
    margin-left: 0.5rem;

    .option__title {
        font-weight: 700;
        font-size: 12.2674px;
        line-height: 18px;
    }
    .option__small {
        font-weight: 500;
        font-size: 12.2674px;
        line-height: 18px;
    }
    .pad_right_0 {
        padding-right: 0;
    }
}
</style>
