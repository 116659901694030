<template>
    <AssessementPreview :assessment="preview" :showPreview="showPreview" :togglePreview="togglePreview" :addAssessment="addAssessment" />
    <ScreenerQst v-if="showScreener" :toggleScreener="toggleScreener" />

    <div @scroll="handleScroll" class="libraryWrapper relative">
        <div class="relative flex mt-[4rem] mb-2 items-center justify-between">
            <div class="fixed top-0 left-0 w-full h-full" v-if="isOpen" @click="toggleFilter"></div>
            <input
                placeholder="Search Question"
                class="!shadow-m !text-sm !py-1 !px-3 !border !border-[1.5px] !rounded-md !h-[3rem] w-[20%] !border-[#e7e7e9]"
                type="text"
                id="searchInput"
                name="searchInput"
                v-model="searchText"
                required
            />
            <div class="flex items-center gap-4">
                <button
                    @click="toggleFilter()"
                    class="bg-white border-[#e7e7e9] !border !border-[1.5px] text-gray-700 inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-m focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Filters <font-awesome-icon class="ml-2" :icon="['fas', 'caret-down']" />
                </button>
            </div>

            <div v-if="isOpen" ref="filterDropdown" class="p-4 absolute w-[20%] shadow-xl bg-[#fff] overflow-y-scroll z-10 top-[103%] right-0">
                <div>
                    <h3 class="text-[16px] mb-2" style="font-weight: 600">Per library:</h3>
                    <label v-for="(library, index) in libraries" :key="index" class="checkbox-label">
                        <input type="checkbox" :value="library" v-model="selectedLibraries" class="checkbox-input" />
                        <span class="checkbox-text">{{ library }}</span>
                    </label>
                </div>
                <div>
                    <h3 class="text-[16px] mt-4 mb-2" style="font-weight: 600">Per category:</h3>
                    <label v-for="(category, index) in categories" :key="index" class="checkbox-label">
                        <input type="checkbox" :value="category" v-model="selectedCategories" class="checkbox-input" />
                        <span class="checkbox-text">{{ category }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div v-if="this.Store.isLoadingQuestions" class="loader">
            <LoadingComponent />
        </div>
        <div class="library-loading" v-else>
            <div v-if="filteredAssessments" class="library-grid-top w-full">
                <QuestionCard
                    v-for="(assess, index) in filteredAssessments"
                    :key="index"
                    :assessement="assess"
                    @openPreview="OpenPreview(assess)"
                    :addAssessment="addQst"
                    :deleteSelectedQst="deleteSelectedQst"
                    :selectedQuestions="selectedQuestions"
                />
            </div>
            <div v-if="!this.Store.customQuestionsCompany && onlyMyLibrary" class="flex items-center justify-center w-full h-[300px]">
                <span class="text-center w-full span-no-cheater">Your custom library is currently empty.</span>
            </div>
        </div>
    </div>
</template>

<script>
// import AssessementTab from "@/components/assessementTab.vue";
// import AssessementTabs from "@/components/assessementTabs.vue";
// import CardsLoader from "@/components/cardsLoader.vue";

import AssessementPreview from "@/components/AssessementPreview.vue";
import QuestionCard from "@/components/QuestionCard.vue";
import { useStore } from "../store/index";
import LoadingComponent from "../components/LoadingComponent.vue";
import ScreenerQst from "../components/ScreenerQst.vue";

export default {
    name: "CustomQuestionLib",
    components: {
        AssessementPreview,
        QuestionCard,
        LoadingComponent,
        ScreenerQst,
        // AssessementTab,
        // AssessementTabs,
        // CardsLoader,
    },
    props: {
        addNewAssessment: Function,
        deleteSelectedQst: Function,
        addQst: Function,
        selectedQuestions: Array,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            // showTab: { hard: false, soft: false, psy: false, top: false },
            isLoading: true,
            isOpen: false,
            preview: {},
            showPreview: false,
            showPreviewWindow: false,
            isVisible: false,
            TopAssessement: [],
            yourAssessment: [],
            filterContainerTop: 11,

            searchPayload: "",

            psychometrics: [],
            selected: [true, false],
            current: 0,
            score: 0,
            premium: false,
            imagePath: "",
            project_id: "",
            message: "",
            isFixed: false,
            scrollThreshold: 350,
            categories: ["Multiple-choice", "Essay"],
            libraries: ["Go Platform", "My library"],
            selectedCategories: [],
            selectedLibraries: [], // By default select both libraries
            searchText: "",
            showScreener: false,
        };
    },
    computed: {
        onlyMyLibrary() {
            return this.selectedLibraries.length === 1 && this.selectedLibraries[0] === "My library";
        },
        filteredAssessments() {
            return this.Store.allCustomQuestions.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const mappedCategories = this.selectedCategories.map((category) => {
                    switch (category) {
                        case "Multiple-choice":
                            return "Multiple-choice";
                        case "Essay":
                            return "Essay";
                        default:
                            return category;
                    }
                });
                const mappedLibraries = this.selectedLibraries.map((library) => {
                    switch (library) {
                        case "Go Platform":
                            return "Go Platform";
                        case "My library":
                            return "My library";

                        default:
                            return library;
                    }
                });
                const matchesCategory = mappedCategories.length === 0 || mappedCategories.includes(assessment.category);

                const matchesLibrary =
                    mappedLibraries.length === 0 || (mappedLibraries.includes("Go Platform") && !assessment.company) || (mappedLibraries.includes("My library") && !!assessment.company);

                return matchesSearch && matchesCategory && matchesLibrary;
            });
        },
    },
    methods: {
        toggleScreener() {
            this.showScreener = !this.showScreener;
        },
        toggleCreateAssesment() {
            this.showCreateAssesment = !this.showCreateAssesment;
        },

        toggleFilter() {
            this.isOpen = !this.isOpen;
        },
        handleScroll() {
            if (this.$refs.libraryWrapper.scrollTop < this.scrollThreshold) {
                this.filterContainerTop = this.$refs.libraryWrapper.scrollTop + 40;
            } else {
                this.filterContainerTop = this.$refs.libraryWrapper.scrollTop + 10;
            }

            console.log({ filterContainerTop: this.filterContainerTop });
        },
        OpenPreview(test) {
            this.preview = test;
            this.togglePreview();
            // console.log(this.showPreview, test);
        },
        togglePreview() {
            this.showPreview = !this.showPreview;
        },
        addAssessment(assessment) {
            console.log({ assessment });
            this.showPreview = false;
            this.addNewAssessment(assessment);
        },
    },

    async mounted() {
        console.log("selected question ARRAY in custom questom component", { selected_questions: this.selectedQuestions });
        this.Store.fetchProjects();
        this.id = this.$route.query.id;
        console.log({ id: this.id });
        this.project_id = this.$route.query.id ? this.$route.query.id : "";
        this.Store.fetchCustomGoPlatform();
        this.Store.fetchCustomCompany();
        // await this.fetchProjectAssessments(this.id);

        // axios
        //     .get("https://server.go-platform.com/AssessmentTest/hardSkills", {
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         console.log({ ressdfjfjbdjgbdfjgbdfgdfg: res });
        //         this.hardSkills = res.data.hardSkills;
        //         this.psychometrics = res.data.psychometrics;
        //         this.softSkills = res.data.softSkills;
        //         this.TopAssessement = res.data.topAssessments;
        //         this.Store.allCustomQuestions = [...this.hardSkills, ...this.psychometrics, ...this.softSkills, ...this.TopAssessement];
        //         console.log("All Assessments:", this.Store.allCustomQuestions);

        //         this.Store.setPremium(res.data.premium);
        //     })
        //     .catch((err) => console.log(err));
    },
};
</script>

<style scoped lang="scss">
.library-grid-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 0;
    gap: 2rem;
    width: 100%;
}

.fadedCards {
    background: rgb(244, 247, 254);
    background: linear-gradient(0deg, rgba(244, 247, 254, 1) 37%, rgba(255, 255, 255, 0) 100%);
    z-index: 5;
}

.span-no-cheater {
    color: #2195f3cd;
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}

.libraryWrapper {
    // display: grid;
    // grid-template-columns: 1fr 3fr;
    // height: 100vh;

    // overflow: scroll;
    .library {
        // margin-left: 20%;
        padding: 1%;
        height: 100vh;
        overflow: scroll;
    }

    .library-loading {
        // margin-left: 20%;
        padding: 0;
        height: auto;
    }
}

.library::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.library::-webkit-scrollbar-track {
    background-color: #2195f328;
    /* Color of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners */
}

.library::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

.library::-webkit-scrollbar-thumb:hover {
    background-color: #2195f328;
    /* Color of the scrollbar thumb on hover */
}

// .fixed-wrapper {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 20%;
//     z-index: 999;
//     background-color: #fff;
//     padding: 10px;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
// }

.filterContainer {
    padding: 10px;
    // background-color: #fff;

    .searchInput {
        background-color: transparent;

        &:focus {
            border: 1px solid #2196f3;
        }
    }
}

.filterContainer::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.filterContainer::-webkit-scrollbar-track {
    // background-color: #f1f1f1; /* Color of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners */
}

.filterContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

.filterContainer::-webkit-scrollbar-thumb:hover {
    // background-color: #555; /* Color of the scrollbar thumb on hover */
}

.categories {
    gap: 8px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    /* Adjust spacing between checkboxes */
    margin-bottom: 10px;
}

.checkbox-input {
    margin-right: 5px;
    /* Adjust spacing between checkbox and label */
    background-color: #2196f3;
}

.checkbox-text {
    margin-left: 5px;
    /* Adjust spacing between checkbox and label */
    font-size: 15px;
    font-weight: 400;
}

.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // padding: 0 1rem;
    border-radius: 8px;
    width: 100%;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 32px;
        color: #05152e;
        padding: 0;

        img {
            margin-right: 5px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 15px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: transparent;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        font-size: 12px;
        // width: 10%;
        background: transparent;

        transform: translateY(-110%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: transparent;
        outline: none;
        border: none;
        transform: translateY(-110%);
        font-size: 10px;
    }

    input,
    textarea {
        border-bottom: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        // border-radius: 8px;
        width: 100%;
        padding: 0 1.5rem;

        &:focus {
            outline: none;
            border-bottom: 1px solid #2196f3;
        }
    }

    input {
        height: 3rem;
        background: #ffffff85;
    }

    textarea {
        resize: none;
        padding-top: 1rem;
    }

    input[type="file"] {
        display: none;
    }

    .phone {
        height: 100%;
        border-radius: 20px;
        padding: 2%;

        & > :nth-child(2) {
            // border: 2px solid red;
            border-radius: 20px;
        }
    }
}

.loader {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screenerBtn {
    &:hover {
        .icon {
            color: #fff;
        }
    }
}
</style>
