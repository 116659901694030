<template>
    <div class="mt-3">
        <p class="text-sm text-gray-600">Please upload a file with the following format:</p>
        <ul class="list-disc pl-5 text-sm text-gray-600">
            <li>First column: First Name</li>
            <li>Second column: Last Name</li>
            <li>Third column: Email</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "ExcelNote",

    data() {
        return {};
    },
};
</script>
<style scoped></style>
