<template>
    <div class="flex flex-col flex-grow p-8">
        <!-- Tabs for navigation -->
        <div class="flex border-b mb-8">
            <div
                v-for="tab in tabs"
                :key="tab"
                class="cursor-pointer pb-2 mx-4 text-[#4C535F] text-[20px]"
                :class="tab === activeTab ? 'border-b border-blue-500 text-blue-500 px-1' : ''"
                @click="selectTab(tab)"
            >
                {{ tab }}
            </div>
        </div>

        <!-- Form Fields -->
        <div class="grid grid-cols-2 gap-8">
            <div>
                <label :for="you" class="block w-[150px] h-[18px] text-[16px] font-manrope text-lg font-medium text-[#4C535F] text-left mb-[4px]"> Experience Type </label>
                <!-- Dropdown (You are) -->
                <select
                    id="you"
                    style="width: 318.28px; height: 52px"
                    :class="['w-full block mt-4 px-4 py-3 rounded-lg', 'text-[14px] leading-[15.74px] font-manrope font-medium text-[#8D98AA]', 'border-[#E0E4EC] bg-[#E0E4EC]']"
                >
                    <option value="Select Experience"></option>
                    <!-- Add options here -->
                </select>
            </div>

            <br />
            <InputWithLabel label="Title" placeholder="Software Engineer" inputWidth="378px" inputHeight="52px" />

            <div>
                <label :for="you" class="block w-[150px] h-[18px] text-[16px] font-manrope text-lg font-medium text-[#4C535F] text-left mb-[4px]"> Managerial Level </label>
                <!-- Dropdown (You are) -->
                <select
                    id="you"
                    style="width: 378px; height: 52px"
                    :class="['w-full block mt-4 px-4 py-3 rounded-lg', 'text-[14px] leading-[15.74px] font-manrope font-medium text-[#8D98AA]', 'border-[#E0E4EC] bg-[#E0E4EC]']"
                >
                    <option value=""></option>
                    <!-- Add options here -->
                </select>
            </div>

            <InputWithLabel label="Company" placeholder="Company name" inputWidth="378px" inputHeight="52px" />

            <InputWithLabel label="Industry" placeholder="Industry" inputWidth="378px" inputHeight="52px" />

            <div style="margin-right: -50px">
                <label class="block w-[200px] h-[18px] text-[16px] text-left font-manrope text-lg font-medium text-[#4C535F] mb-[4px]">Starting Date</label>
                <input
                    type="date"
                    :class="['w-full block mt-4 px-4 py-3 rounded-lg', 'text-[14px] leading-[15.74px] font-manrope font-medium text-[#8D98AA]', 'border-[#E0E4EC] bg-[#E0E4EC]']"
                    style="width: 258px; height: 52px"
                />
            </div>

            <div>
                <label class="block w-[200px] h-[18px] text-[16px] text-left font-manrope text-lg font-medium text-[#4C535F] mb-[4px]">End Date</label>
                <input
                    type="date"
                    :class="['w-full block mt-4 px-4 py-3 rounded-lg', 'text-[14px] leading-[15.74px] font-manrope font-medium text-[#8D98AA]', 'border-[#E0E4EC] bg-[#E0E4EC]']"
                    style="width: 258px; height: 52px"
                />
            </div>
            <InputWithLabel label="Position Description" placeholder="Mention all the tasks you handle during the job." inputWidth="844px" inputHeight="185px" />
        </div>

        <!-- Buttons -->
        <div class="flex space-x-4 mt-8">
            <button class="px-6 py-2 bg-blue-500 text-white rounded-lg">Add</button>
            <button class="px-6 py-2 bg-gray-300 text-black rounded-lg">Reset</button>
        </div>
    </div>
</template>

<script>
import InputWithLabel from "./ReusableComponents/InputWithLabel.vue";

export default {
    components: {
        InputWithLabel,
    },
    data() {
        return {
            tabs: ["Skills", "Experience", "Education", "Certifications & Awards", "Preferences", "Account Setting"],
            activeTab: "Experience",
        };
    },
    methods: {
        selectTab(tab) {
            this.activeTab = tab;
            // You can implement the scroll or redirect here
            if (tab === "Account Setting") {
                // Implement your redirection logic here (if using router)
                this.$router.push({ name: "accountSettings" });
            } else if (tab === "Experience") {
                // Scroll to Experience section or perform any other action
                this.$router.push({ name: "accountExperience" });
            }
        },
    },
};
</script>

<style scoped>
/* Additional styling here */
</style>
