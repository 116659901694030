<template>
    <div
        :class="{
            'message-right': isSentByCurrentUser,
            'message-left': !isSentByCurrentUser,
        }"
    >
        <div class="message-time">{{ formattedTime }}</div>
        <div class="message-body">
            <p>{{ message }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        username: String,
        time: Date,
    },
    computed: {
        isSentByCurrentUser() {
            return this.username === this.$store.state.currentUser.username;
        },
        formattedTime() {
            const date = new Date(this.time);
            const hours = date.getHours();
            const minutes = "0" + date.getMinutes();
            return `${hours}:${minutes.substr(-2)}`;
        },
    },
};
</script>

<style scoped>
.message-right {
    text-align: right;
}
.message-body {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
}
</style>
