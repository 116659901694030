<template>
    <div class="wrapper" v-if="showPreviewWindow">
        <div>
            <!-- <div class="w-full mb-[3%] flex justify-end" v-if="!this.$route.query.id">
                <select name="projetList" id="projectList" v-model="project_id" class="h-fit max-w-1/2 w-60 py-2 outline-none border-none rounded-md shadow-[0_0_6px_0_rgba(0,0,0,30%)] bg-white">
                    <option value="">Select a project</option>
                    <option v-for="project in projectList" :key="project.id" :value="project.id">
                        {{ project.name }}
                    </option>
                </select>
            </div> -->
            <div class="success-score">
                <div>
                    <h1 class="title">{{ $t("Set Success Score") }}</h1>
                    <div class="slider">
                        <input
                            type="range"
                            min="1"
                            max="100"
                            v-model="sliderValue"
                            id="success-slider"
                            :style="{
                                background: `linear-gradient(90deg, #2196f3 ${sliderValue}%, #FFF ${sliderValue}%)`,
                            }"
                        />
                        <div class="success-score-input">
                            <input type="number" min="1" max="100" v-model="sliderValue" id="success-score" />
                            <span>%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="assessments-section">
                <div class="w-full flex flex-row justify-between items-center">
                    <h1 class="title">{{ $t("Selected Assessments") }}:</h1>
                </div>
                <div class="assessments">
                    <LibraryCard v-for="(assess, index) in yourAssessment" :key="index" :assessement="assess" @click="OpenPreview(assess)" @delete="deleteAssessement(assess)" />
                </div>
            </div>
            <div class="preview-btn">
                <button @click="toggleshowPreviewWindow">Cancel</button>
                <button
                    @click="
                        () => {
                            this.updateAssessments(this.project_id, this.sliderValue);
                        }
                    "
                >
                    {{ $t("Select") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LibraryCard from "@/components/LibraryCard.vue";
import { useStore } from "../store/index";
export default {
    components: { LibraryCard },
    name: "ConfirmAssessments",

    props: {
        yourAssessment: {
            type: Array,
        },
        score: {
            type: Number,
        },
        toggleshowPreviewWindow: {
            type: Function,
        },
        showPreviewWindow: {
            type: Boolean,
        },
        deleteAssessement: {
            type: Function,
        },
        updateAssessments: {
            type: Function,
        },
        project_id: {
            type: String,
        },
    },
    data() {
        return {
            sliderValue: this.score,
            jobTitle: "",
            jobs: [],
            // project_id: "",
        };
    },
    watch: {
        // Watch for changes to the score prop
        score(newScore) {
            // Update the localScore when the prop changes
            this.sliderValue = newScore;
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {
        projectList() {
            return this.Store.getProjectList;
        },
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0000002f;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    > :first-child {
        width: 60%;
        height: fit-content;
        border-radius: 20px;
        background-color: #ffffff;
        padding: 2%;
    }
}
.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
    border-radius: 20px;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #05152e;
        img {
            margin-right: 5px;
        }
    }
    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 25px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input,
    textarea {
        border: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 20px;
        width: 100%;
    }
    input {
        height: 3rem;
    }
    textarea {
        resize: none;
    }
    input[type="file"] {
        display: none;
    }
    .phone {
        height: 100%;
        border-radius: 20px;
        padding: 2%;

        & > :nth-child(2) {
            // border: 2px solid red;
            border-radius: 20px;
        }
    }
}

.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin: 1%;
}
.assessments-section {
    .assessments {
        width: 90%;
        padding: 24px 8px 24px 24px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        align-items: flex-start;
        gap: 16px;
    }
}

.preview-btn {
    //grid-area: btns;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    justify-content: center;
    gap: 1rem;
    align-items: flex-end;
    margin-top: 3%;
    > * {
        width: 20%;
        height: 100%;
        padding: 0.25rem;
        border-radius: 20px;
        color: #fff;
        background: #2196f3;
        border: none;
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover {
            transform: scale(1.05);
        }
    }
    > :first-child {
        background: #fff;
        box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);
        color: #050708;
        border: 1px solid #c3cad9;
    }
}

.jobTitle {
    > :nth-child(2) {
        option {
            background-color: red;
        }
    }
}
.success-score {
    display: grid;
    // grid-template-columns: 6fr 1fr;
    padding: 2%;
    background-color: #2195f311;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-radius: 20px;

    h1 {
        margin-left: 0;
    }
}

.slider {
    display: flex;
    align-items: center;
    width: 100%;
}

.success-score-input {
    background: #fff;
    border: 1px solid #e5e5ef;
    border-radius: 12px;
    padding: 8px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    border: 1px solid #2196f3;
    border-radius: 90px;
    margin-right: 48px;
    width: 100%;
    height: 16px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #2196f3;
    cursor: pointer;
}

#success-score {
    border: none;
    margin: 0;
    font-family: "Roboto";
    font-weight: 500;
    width: 56px;
    font-size: 24px;
    text-align: center;
    color: #000000;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
