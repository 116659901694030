<template>
    <div v-if="isVisible" class="confirmWrapper">
        <div class="closeArea"></div>
        <div class="confirmEmail">
            <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 174 164" style="overflow: visible" fill="none">
                <g clip-path="url(#clip0_5413_23374)" style="overflow: visible">
                    <path
                        class="rotate-path"
                        d="M172.936 91.6565C166.061 123.844 140.142 154.152 103.773 160.924C86.0355 164.232 67.6355 162.215 51.1931 155.161C34.7508 148.107 21.1042 136.376 12.1966 121.637C3.28903 106.898 -0.425536 89.904 1.58185 73.0737C3.58923 56.2433 11.2162 40.4349 23.3768 27.8995C48.3193 2.17522 90.4356 -4.90603 124.811 7.96897"
                        stroke="#2196f3"
                        stroke-width="8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path d="M56.0625 78.7812L90.4375 110.969L172.938 27.2812" stroke="#2196f3" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </svg>
            <span class="text">We've sent you a confirmation email.</span>
            <br />
            <span class="inbox">Check your inbox!</span>
            <router-link to="/login" class="cursor-pointer w-[100px] mt-5 h-[40px] text-[#fff] rounded-md bg-[#2196f3] flex items-center justify-center">Close</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmEmail",
    props: {
        isVisible: Boolean,
        toggleConfirmEmail: Function,
    },
};
</script>
<style scoped lang="scss">
.confirmWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .closeArea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .confirmEmail {
        width: 50%;
        height: 60%;
        border-radius: 20px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .text {
            font-family: "DM Sans";
            font-style: normal;
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            color: #0f172a;
            margin-top: 5%;
        }
        .inbox {
            padding: 0 2rem;
            color: var(--Secondary-Grey-900, #0f172a);
            font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 42px; /* 116.667% */
            letter-spacing: -0.72px;
            margin-right: 0;
        }
    }
}
@keyframes rotateCircle {
    0% {
        transform: rotateY(0deg) translateX(0) translateY(0);
    }
    50% {
        transform: rotateY(180deg) translateX(0) translateY(0);
    }
    100% {
        transform: rotateY(360deg) translateX(0) translateY(0);
    }
}

.rotate-path {
    animation: rotateCircle 1s linear; /* Adjust the duration as needed */
    transform-origin: center center; /* Set the rotation origin to the center of the circle */
}
</style>
