<template>
    <div class="circular-slider">
        <svg viewBox="0 0 36 36">
            <path
                class="circle-bg"
                d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                class="circle"
                :stroke="sliderValue < 33 ? '#EA4745' : sliderValue < 66 ? '#ffa500' : '#46A997'"
                :stroke-dasharray="`${sliderValue}, 100`"
                d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="20.35" class="percentage">{{ sliderValue }}%</text>
        </svg>
    </div>
</template>

<script>
export default {
    name: "CircleSlider",
    props: {
        value: Number,
    },
    data() {
        return {
            sliderValue: this.value, // Default value
        };
    },
    watch: {
        value(newValue) {
            this.sliderValue = newValue;
        },
    },
};
</script>

<style scoped>
.circular-slider {
    max-width: 40px;
    max-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circular-slider svg {
    width: 100%;
    height: auto;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    /* stroke: #2196f3; */
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.5s;
}

.percentage {
    fill: #000;
    font-family: Arial, sans-serif;
    font-size: 10px;
    font-weight: 300;
    text-anchor: middle;
}

.slider-input {
    margin-top: 10px;
}

.input {
    width: 50px;
    text-align: center;
}
</style>
